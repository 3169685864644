import consumer_excessive_application from '@/lib/data-service/haolv-default/consumer_excessive_application'
import Pagination from '@/component/pagination/index.vue'
import moment from "moment";
export default {
    data () {
        return {
            params: {
                applyNo: '',
                railwayTicketType: 0,
                dateArr: [],
                currentPage: 1,
                pageSize: 10,
                endTime: '',
                startTime: '',
                type: 2,
                total: 0,
                // timeList: []
            },
            timeOptionRange: null,
            options: [
                {
                    label: '非差标坐席',
                    value: 0
                }
            ],
            list: [],
            layout: 'total, sizes, prev, pager, next'
        }
    },
    components: {
        Pagination
    },
    created () {},
    mounted () {},
    activated () {
        // this.setTimeList()
        this.getExcessiveApplication()
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        setTimeList() {
            if (this.params.timeList.length === 0) {
                let now = moment()
                let year = now.year()
                let month = now.month()
                console.log(year, month)
                let timeList = []
                for (let i = 1, l = month + 2; i < l; i++) {
                    let num = i > 9 ? i.toString() : '0' + i
                    let item = {
                        dataTime: `${year}-${num}`,
                        dataMonth: num
                    }
                    timeList.push(item)
                }
                console.log(timeList)
                this.params.timeList = timeList
            }
        },
        changeOrderDate(val) {
            if (val === '' || val === null) {
                this.params.startTime = ''
                this.params.endTime = ''
            } else {
                this.params.startTime = val[0]
                this.params.endTime = val[1]
            }
            /*console.log(val)
            if (val === '' || val === null) {
                this.params.startTime = ''
                this.params.endTime = ''
                this.params.timeList = []
                this.setTimeList()
            } else {
                let bigMonth = ['01', '03', '05', '07', '08', '10', '12']
                let currentMonth = val[0].split('-')[1]

                this.params.startTime = val[0] + '-01'
                this.params.endTime = `${val[1]}-${bigMonth.indexOf(currentMonth) > -1 ? '31' : '30'}`

                let year = this.params.startTime.split('-')[0]
                let startMonth = parseInt(this.params.startTime.split('-')[1])
                let endMonth = parseInt(this.params.endTime.split('-')[1])
                let timeList = []
                for (let i = startMonth, l = endMonth + 1; i < l; i++) {
                    let num = i > 9 ? i.toString() : '0' + i
                    let item = {
                        dataTime: `${year}-${num}`,
                        dataMonth: num
                    }
                    timeList.push(item)
                }
                console.log(timeList)
                this.params.timeList = timeList
            }*/
        },
        getExcessiveApplication() {
            consumer_excessive_application(this.params).then(res => {
                this.list = res.pageResult.pageData
                this.params.total = res.pageResult.totalCount
            }).catch(() => {
                this.list = []
                this.params.total = 0
            })
        },
        search() {
            this.params.currentPage = 1
            this.getExcessiveApplication()
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/